import './App.css';
import { SlSocialInstagram, SlSocialFacebook } from "react-icons/sl"
import { SiEtsy, SiSociety6, SiTiktok } from 'react-icons/si'

const currentDate = new Date()

function App() {
  return (
    <div className="main flex bg">
      <div className="header flex">
        <h1 >Mythical Mind Studio</h1>
        <h2 >COMING SOON</h2>
      </div>
      <div className="social-container flex">
        <div className="social flex">
          <a
            href="https://instagram.com/mythicalmindstudio?igshid=NTc4MTIwNjQ2YQ=="
            target="_blank"
            rel="noreferrer noopener"
            title="Mythical Mind Studio on Instagram"
          ><SlSocialInstagram color="#400040" /></a>
        </div>
        <div className="social flex">
          <a
            href="https://instagram.com/spooky_doodz?igshid=NTc4MTIwNjQ2YQ=="
            target="_blank"
            rel="noreferrer noopener"
            title="Spooky Doodz on Instagram"
          ><SlSocialInstagram color="#400040" /></a>
        </div>
        <div className='social flex'>
          <a
            href="https://www.tiktok.com/@mythicalmindstudio?_t=8dAGVrgdUwU&_r=1"
            target="_blank"
            rel="noreferrer noopener"
            title="Mythical Mind Studio on TikTok"
          ><SiTiktok color="#400040" /></a>
        </div>
        <div className='social flex'>
          <a
            href="https://www.facebook.com/ryan.chamel"
            target="_blank"
            rel="noreferrer noopener"
            title="Ryan Chamel on Facebook"
          ><SlSocialFacebook color="#400040" /></a>
        </div>
        <div className='social flex'>
          <a
            href="https://www.etsy.com/au/shop/MythicalMindStudio?ref=shop-header-name&listing_id=1417052904"
            target="_blank"
            rel="noreferrer noopener"
            title="Mythical Mind Studio on Etsy"
          ><SiEtsy color="#400040" /></a>
        </div>
        <div className='social flex'>
          <a
            href="https://www.society6.com/cosmictaco"
            target="_blank"
            rel="noreferrer noopener"
            title="Mythical Mind Studio on Society6"
          ><SiSociety6 color="#400040" /></a>
        </div>
      </div>
      <div className="footer">
        <p>MYTHICAL MIND STUDIO</p>
        <span> © {currentDate.getFullYear()}</span>
      </div>
    </div>
  );
}

export default App;